import IFilter from './IFilter'
import EmptyFilter from './EmptyFilter'
import { getMongoOperator } from './utils/mongo.utils'

/**
 * This class handle the Exist Filters
 * @author Reynaldo
 * Example
 *  {
        "type": "EXIST",
        "field": "um.id.keyword",
        "value": true | false
    }
 **/


export default class ExistFilter extends IFilter {
  
  /**
   * @param filter {Object}
   **/
  constructor (filter) {
    super()
    const { field, value } = filter
    this.type = 'EXISTS'
    this.field = field
    this.value = value || false
    this.filterObject = filter
  }
  
  clone () {
    return new TermFilter(this.toQuery())
  }
  
  toQuery () {
    if (!this.field)
      return this.filterObject
    return {
      type: 'EXISTS',
      field: this.field,
      value: this.value
    }
  }
  
  toMongoQuery () {
    if (!this.field)
      return this.filterObject
    return { [this.field]: { [getMongoOperator(this.type)]: this.value } }
  }
  
  removeFilter () {
    return new EmptyFilter()
  }
}
