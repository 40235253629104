import { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DatePreview = ({ value }) => {
  if (value) return moment(value).format('LL').toString();
  return '-';
};

export default memo(DatePreview);

DatePreview.propTypes = {
  value: PropTypes.string
};
