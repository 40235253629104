import React, {memo} from 'react';
import {DflPageHeader} from '@dfl/common-components';
import PropTypes from 'prop-types';
import {Skeleton} from 'antd';


const titlePlaceholder = <Skeleton paragraph={false} title className={'w-32'} active/>;
const subtitlePlaceholder = <Skeleton paragraph={false} title className={'w-56 -mt-4'} active/>;

const SpaceHeader = ({space, links, linksMap, isLoading, children, ...props}) => {
  const url = <a href={space?.url} target="_blank"
    rel="noreferrer">{space?.url}</a>;
  const title = isLoading ? titlePlaceholder : space?.name;
  const subTitle = isLoading ? subtitlePlaceholder : url;
  const backgroundColor = isLoading ? '#efefef' : space?.metadata?.color || '#39B0FF';

  return (
    <div style={{backgroundColor}}
      className={'transition ease-in-out  duration-300'}>
      <DflPageHeader links={links} linksMap={linksMap} title={title}
        dark
        center
        avatar={{src: space?.logo}}
        subTitle={subTitle} {...props}>
        {children}
      </DflPageHeader>
    </div>
  );
};

export default memo(SpaceHeader);

SpaceHeader.propTypes = {
  links: PropTypes.array,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  space: PropTypes.object,
  linksMap: PropTypes.object
};
