import ValidatorProvider from './ValidatorProvider'

export const NAME_REGEX = /^([A-Za-zÁÉÍÓÚñáüéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáüéíóúÑ']+[\s]?)+([A-Za-zÁÉÍÓÚñáéüíóúÑ]{0}?[A-Za-zÁÉÍÓÚüñáéíóúÑ'])+[\s]?([A-Za-zÁÉÍÓÚüñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñüáéíóúÑ'])?$/i
export const LAST_NAME_REGEX = /^([A-Za-zÁÉÍÓÚñáüéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáüéíóúÑ\-']+[\s]?)+([A-Za-zÁÉÍÓÚñáéüíóúÑ]{0}?[A-Za-zÁÉÍÓÚüñáéíóúÑ'])+[\s]?([A-Za-zÁÉÍÓÚüñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñüáéíóúÑ'])?$/i
export const WITH_DIGITS_REGEX = /^([A-Za-zÁÉÍÓÚñáüéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáüéíóúÑ0-9']+[\s]?)+$/i

export const isName = (value, supportNumbers) => {
  const regexToUse = supportNumbers ? WITH_DIGITS_REGEX : NAME_REGEX
  // eslint-disable-next-line
  return regexToUse.test(value)
}

export const isLastName = (value) => {
  // eslint-disable-next-line
  return LAST_NAME_REGEX.test(value)
}

export const isNameWithDigits = (value) => {
  return isName(value, true)
}

export const NameRuler = {
  
  validator (rule, value) {
    if (isName(value) || !value) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidName'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

export const LastNameRuler = {
  
  validator (rule, value) {
    if (isLastName(value) || !value) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidLastName'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

export const NameWithDigitsRuler = {
  
  validator (rule, value) {
    if (isNameWithDigits(value, true) || !value) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidName'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

export const PHONE_REGEX = /^\+?([0-9]{1,2})\)?[-. (]?([0-9]{1,4})[-. )]?([0-9]{1,4})[-. )]?([0-9]{4,8})$/

export const isPhoneNumber = (value = '', cuba) => {
  const withOutSymbol = value.replace(/[+\s]/g, '')
  let phone = cuba && withOutSymbol.length < 10 ? `53${withOutSymbol}` : withOutSymbol
  return !!phone.match(PHONE_REGEX)
}

export const PhoneNumberRuler = {
  
  validator (rule, value) {
    
    if (isPhoneNumber(value) || !value || !value.trim()) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidPhoneNumber'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

export const PhoneCubaNumberRuler = {
  
  validator (rule, value) {
    
    if (isPhoneNumber(value, true) || !value || !value.trim()) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidPhoneNumber'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

export const LATTER_AND_NUMBER_REGEX = /^([a-zñáéíóúü]|[A-ZÁÉÍÓÚÑ]|\s|\d)*$/

export const isLatterAndNumber = (value = '') => {
  return !!value.match(LATTER_AND_NUMBER_REGEX)
}

export const LatterAndNumberRuler = {
  
  validator (rule, value) {
    
    if (isLatterAndNumber(value) || !value || !value.trim()) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidLetterAndNumber'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

export const isUrlOrSite = (value) => {
  try {
    const url = new URL(value)
    return !!url
  } catch (e) {
    return false
  }
}

export const URLRuler = {
  
  validator (rule, value) {
    if (isUrlOrSite(value) || !value) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidUrlOrSite'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}
