import FilterFactory from "./FilterFactory";

export default class IFilter {

    /*
   * This method build all the filters to IFilter Objects
   * @param filters {Array | Object} The filters can be passed in raw data (JSON) or IFilter objects
   **/
    buildFilters(filters) {
        if (Array.isArray(filters))
            return filters.map(filter => {
                return this.buildFilter(filter);
            });
        return this.buildFilter(filters);
    }

    /*
   * This method build all the filters to IFilter Objects
   * @param filters {Object | IFilter} The filter can be a raw data filter (JSON) or a IFilter object
   **/
    buildFilter(filter) {
        if (filter instanceof IFilter) {
            return filter;
        }
        if (filter.isFilter) {
            return filter;
        }
        return FilterFactory.factory(filter);
    }

    remove() {
        throw new Error('Not implemented yet!')
    }

    clone() {
        throw new Error('Not implemented yet!')
    }

    toQuery() {
        throw new Error('Not implemented yet!')
    }

    toMongoQuery() {
        throw new Error('Not implemented yet!')
    }
    get isFilter (){
        return true;
    }
}
