import { isDate, isLength } from 'validator'
import ValidatorProvider from './ValidatorProvider'

export const isCubaCI = (value = '') => {
  if (isLength(value, 11, 11) && value.match(/^\d{11}$/)) {
    const year = value.substring(0, 2)
    const month = value.substring(2, 4)
    const day = value.substring(4, 6)
    return isDate(`19${year}/${month}/${day}`)
  }
}

export const CubaCIRuler = {
  
  validator (rule, value) {
    if (isCubaCI(value, 11, 11) || !value || !value.trim()) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidCubaCI'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}

/**
 * Código REEUP debe tener el formato: NNN.N.NNNNN o NNN.N.NNNNNN
 * @param value
 * @returns {*}
 */
export const isREEUPCode = (value = '') => {
  return isLength(value, 11, 12) && !!value.match(/^[\d]{3}.\d.[\d]{5,6}$/)
}

export const CubaREEUPCodeRuler = {
  
  validator (rule, value) {
    if (isREEUPCode(value) || !value || !value.trim()) {
      return Promise.resolve()
    }
    
    const message = rule.message || 'invalidCubaREEUPCode'
    
    return Promise.reject(ValidatorProvider.t(message))
  },
  
}
