import React, {memo} from 'react';
import {Badge} from 'antd';

const Ribbon = ({text, show, children, ...props}) => {
  if (show)
    return (
      <Badge.Ribbon text={text}
        {...props}>
        {children}
      </Badge.Ribbon>
    );
  return children;

};

export default memo(Ribbon);
