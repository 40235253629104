import React, { memo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useUser } from '../..'
import PropTypes from 'prop-types'

const SecureRouteAnyPermission = ({
  path,
  authenticated,
  permissions,
  data,
  notfoundRedirect,
  ...props
}) => {
  const { isAuthenticated, hasPermission } = useUser()

  const { component: Component, disabled, ...rest } = props

  if (
    disabled ||
    (authenticated && !isAuthenticated) ||
    (permissions && !hasPermission(permissions, true))
  )
    return <Redirect to={notfoundRedirect} />

  if (data) {
    return (
      <Route path={path} {...rest} render={() => <Component {...data} />} />
    )
  }
  return <Route path={path} {...props} />
}

export default memo(SecureRouteAnyPermission)

SecureRouteAnyPermission.propTypes = {
  authenticated: PropTypes.bool,
  data: PropTypes.any,
  notfoundRedirect: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  permissions: PropTypes.any
}
