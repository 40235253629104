/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useMemo} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';


const Portal = ({portal, children}) => {
  const element = useMemo(() => {
    return document.getElementById(portal);
  }, []);


  return createPortal(
    children,
    element
  );
};

Portal.propTypes = {
  portal: PropTypes.string,
  children: PropTypes.any
};

export default memo(Portal);
