import IFilter from './IFilter'
import EmptyFilter from './EmptyFilter'

/**
 * This class handle the Range Filters
 * @author Yadian
 * Example
 *  {
        "type": "RANGE",
        "field": "createdAt",
        "from": "2020-07-10",
        "to": "2020-07-16"
    }
 **/


export default class RangeFilter extends IFilter {
  
  /**
   * @param filter {Object}
   **/
  constructor (filter) {
    super()
    const { type, field, from, to } = filter
    this.type = type.toUpperCase()
    this.field = field
    this.from = from
    this.to = to
  }
  
  clone () {
    return new RangeFilter(this.toQuery())
  }
  
  toQuery () {
    if (this.from === undefined && this.to === undefined)
      return
    
    return {
      type: 'RANGE',
      field: this.field,
      from: this.from,
      to: this.to
    }
  }
  
  toMongoQuery () {
    const query = {}
    const isFromDefined = this.from != undefined && this.from != null
    const isToDefined = this.to != undefined && this.to != null
    if (!isFromDefined && !isToDefined)
      return null
    if (isFromDefined)
      query['$gte'] = this.from
    if (isToDefined)
      query['$lte'] = this.to
    return { [this.field]: query }
  }
  
  removeFilter () {
    return new EmptyFilter()
  }
}
