import {locale} from './defaultLocale'

class ValidatorSetting {
  set In18(in18) {
    this.in18 = in18;
  }

  get In18() {
    return this.in18;
  }

  t(text) {
    if (this.In18)
      return this.In18.t(text);
    return locale[text] || text;
  }
}

export default new ValidatorSetting();
