import React, {memo} from 'react';
import {Menu} from 'antd';
import PropTypes from 'prop-types';
import NavMenu from './index';

const NavigationGroup = ({title, menu, mode, theme, index, onClick, menuProps, subMenuProps}) => {
  return (
    <Menu mode={mode} theme={theme}>
      <Menu.ItemGroup title={title}>
        <NavMenu
          menu={menu}
          index={index}
          theme={theme}
          onClick={onClick}
          menuProps={menuProps}
          subMenuProps={subMenuProps}/>
      </Menu.ItemGroup>
    </Menu>
  );
};

NavigationGroup.propTypes = {
  menu: PropTypes.array,
  title: PropTypes.string,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  index: PropTypes.any,
  menuProps: PropTypes.object,
  subMenuProps: PropTypes.object,
};

NavigationGroup.defaultProps = {
  menu: [],
  mode: 'inline',
  onClick: e => e,
  menuProps: {},
  subMenuProps: {},
};

export default memo(NavigationGroup);
