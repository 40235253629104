import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

const Help = ({children, help}) => {
  if (!help) {
    return children;
  }

  return <React.Fragment>
    {children}
    <Tooltip title={help}>
      <span className={'mx-2 text-base'}>
        <InfoCircleOutlined className={'opacity-50'}/>
      </span>
    </Tooltip>
  </React.Fragment>;
};

Help.propTypes = {
  children: PropTypes.any,
  help: PropTypes.string
};

export default memo(Help);
