import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {StarFilled} from '@ant-design/icons';
import style from './index.less';
import classNames from 'classnames';

const FavoriteButton = ({favorite,className, onChange}) => {
  return (
    <StarFilled onClick={onChange} className={classNames(style.button, {[style.favorite]: favorite}, className)}/>
  );

};

export default memo(FavoriteButton);

FavoriteButton.propTypes = {
  favorite: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
