import React, {memo} from 'react';
import {Card} from 'antd';
import style from './index.less';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CardSelectable = ({className, hoverable, isActive, onClick, ...props}) => {

  return (
    <Card
      onClick={onClick}
      hoverable={hoverable}
      className={classNames(className, {[style.active]: isActive})}
      {...props}
    />
  );

};

export default memo(CardSelectable);

CardSelectable.propTypes = {
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

CardSelectable.defaultProps = {
  hoverable: true
};
