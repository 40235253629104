import { ApiClientService } from './index'
import isNil from 'lodash/isNil'
import pick from 'lodash/pick'
import forEach from 'lodash/forEach'

class EntityApiService {
  constructor(path) {
    this.path = path
    this.availableQueryParams = ['in18']
  }

  getDefaultQueryParams(params = {}) {
    const toParams = pick(params, this.availableQueryParams)
    const queryParams = new URLSearchParams()
    forEach(toParams, (value, key) => {
      if (!isNil(value)) {
        queryParams.append(key, value)
      }
    })
    const stringQueryParams = queryParams.toString()

    return stringQueryParams && '?' + stringQueryParams
  }

  /**
   *Get the request path
   * @param {string| null} concat custom path to the entity path
   * @param {any} params od the request, util to create custom
   * path url according to the request body
   * @return {string}
   **/
  getPath(concat, params) {
    return this.path + (concat || '') + this.getDefaultQueryParams(params)
  }

  search = (params, config) => {
    return ApiClientService.post(
      `${this.getPath('/search', params)}`,
      params,
      config
    )
  }

  getOne = (id, config) => {
    return ApiClientService.get(this.getPath(`/${id}`, config), config)
  }

  save = (params, config) => {
    return ApiClientService.post(this.getPath(null, params), params, config)
  }

  update = (id, params, config) => {
    if (typeof id === 'object') {
      params = id
      id = id._id
    }
    return ApiClientService.patch(
      this.getPath(`/${id}`, params),
      params,
      config
    )
  }

  delete = (id, params, config) => {
    return ApiClientService.delete(
      this.getPath(`/${id}`, params),
      params,
      config
    )
  }

  deleteMany = (data, config = {}) => {
    return ApiClientService.delete(`${this.getPath(null, data)}`, {
      ...config,
      data
    })
  }

  recover = (id, params, config) => {
    return ApiClientService.patch(
      this.getPath(`/${id}/recover`, params),
      params,
      config
    )
  }
}

export default EntityApiService
