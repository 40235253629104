import React, {memo} from 'react';
import {Card, Row, Col, Typography} from 'antd';
import PropTypes from 'prop-types';

const { Title, Text } = Typography;

const SmallInfoDashboard = ({height, width, icon, amount, text}) => {
  const Icon = icon;

  return (
    <Card className="mt-4">
      <Row>
        <Col span={8}>
          <div className="pt-5">
            <Icon height={height} width={width} />
          </div>
        </Col>
        <Col span={16}>
          <div className="ml-6">
          <Title level={2} className="mb-0" style={{fontSize: '32px'}}>
             {amount}
          </Title>
          <Text strong>
            {text}
          </Text>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default memo(SmallInfoDashboard);

SmallInfoDashboard.propTypes = {
  bordered: PropTypes.bool,
  component: PropTypes.object,
};


SmallInfoDashboard.defaultProps = {
  bordered: false,
};
