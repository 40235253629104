export const getMongoOperator = (type) => {
  
  //add here custom operators
  
  return `$${type.toLowerCase()}` //AND => $AND | OR=> $OR | NOR=>$nor;
}

export const BSONTypes = {
  'double': 1,
  'string': 2,
  'object': 3,
  'array': 4,
  'binData': 5,
  'undefined': 6,
  'objectId': 7,
  'bool': 8,
  'date': 9,
  'null': 10,
  'regex': 11,
  'int': 16,
  'timestamp': 17,
  'long': 18,
  'decimal': 19
}
