import IFilter from './IFilter';

/**
 * This class handle the Term Filters
 * @author Yadian
 * Example
 *  {
        "type": "TERM",
        "field": "um.id.keyword",
        "value": "bb4cd88-c6b8-4a77-956a-0671189be008"
    }
 **/


export  default class EmptyFilter extends IFilter {

    constructor() {
        super();
    }

    remove() {
        throw new Error('Not implemented yet!');
    }

    clone() {
        return new EmptyFilter();
    }

    toQuery() {
        return;
    }
    toMongoQuery() {
        return;
    }
    get isEmpty() {
        return true;
    }

}


// module.exports = EmptyFilter;