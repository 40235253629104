export const locale = {
  invalidName: 'Invalid name',
  invalidLastName: 'Invalid last name',
  invalidPostalCode: 'Invalid postal code',
  invalidUsername: 'Invalid username',
  invalidUsernameOrEmail: 'Invalid username or email',
  passStrength: 'Insufficient password strength',
  invalidCubaCI: 'Invalid CI',
  invalidPhoneNumber: 'Invalid phone number',
  invalidCreditCard: 'Invalid card number',
  invalidExpiryDate: 'Invalid card expiry date',
  invalidLetterAndNumber: 'Invalid value',
  invalidCvc: 'Invalid CVC',
};
