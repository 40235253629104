import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import style from './index.less';

const CustomColorPicker = ({ value, onChange, size, borderRadius, colors, borderColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const defaultColor = useMemo(
    () => (colors.includes(value) ? 'black' : value),
    [value, colors]
  );

  return (
    <div
      className={style.divBtnTheme}
      style={{
        width: `${size + 20}px`,
        height: `${size + 20}px`,
        borderRadius: `${borderRadius}px`,
        border: `3px solid ${borderColor || defaultColor}`
      }}
      onClick={handleClick}
    >
      <div
        className={style.divAddBtnTheme}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: `${borderRadius}px`,
          backgroundColor: defaultColor
        }}
        onClick={handleClick}
      />
      {!!isOpen && (
        <div className={style.popover}>
          <div className={style.cover} onClick={handleClose} />
          <SketchPicker color={value} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

CustomColorPicker.propTypes = {
  icon: PropTypes.any,
  size: PropTypes.number,
  borderRadius: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  borderColor: PropTypes.string
};

CustomColorPicker.defaultProps = {
  value: '#48B5FE',
  size: 25,
  borderRadius: 23,
  borderColor: ''
};

export default memo(CustomColorPicker);
