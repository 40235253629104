import PropTypes from 'prop-types';

const StepContent = ({step, children}) => {
  if (Array.isArray(children) && children.length)
    if (step < children.length)
      return children[step];
    else
      return children[children.length - 1];

  return children;

};

StepContent.propTypes = {
  step: PropTypes.number,
  children: PropTypes.any,
};

export default StepContent;
