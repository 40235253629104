import React from 'react'
import PropTypes from 'prop-types'
import { AuthProvider } from './AuthContext'
import { UserProvider } from './UserContext'

const AppProviders = ({ children, authClient, PageLoader, ...props }) => {
  return (
    <AuthProvider authClient={authClient} PageLoader={PageLoader} {...props}>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  )
}

AppProviders.propTypes = {
  PageLoader: PropTypes.any,
  authClient: PropTypes.any,
  children: PropTypes.any,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  isRejected: PropTypes.bool,
  isSettled: PropTypes.bool,
  reload: PropTypes.func,
  user: PropTypes.object
}

export default AppProviders
