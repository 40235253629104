import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getModuleInfo} from '../../constant/modules.constant';
import {Card} from 'antd';

const SpaceModuleCard = ({module, className}) => {
  const moduleFound = getModuleInfo(module);
  const ModuleImage = moduleFound?.image;

  return (<Card className={classNames('flex items-center justify-center',className)}>
    {ModuleImage && <ModuleImage height={35.38} width={121}/>}
  </Card>);

};
SpaceModuleCard.propTypes = {
  module: PropTypes.string,
  className: PropTypes.string,
};

export default memo(SpaceModuleCard);


