import {isPostalCode as _isPostalCode} from "validator";
import ValidatorProvider from "./ValidatorProvider";

export const isPostalCode = (value) => _isPostalCode(value, 'any');

export const PostalCodeRuler = {

  validator(rule, value) {
    if (isPostalCode(value) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidPostalCode';

    return Promise.reject(ValidatorProvider.t(message));
  },

};


