import React, {memo} from 'react';
import {Space} from 'antd';
import PropTypes from 'prop-types';
import Help from '../Help';

const stopPropagation = (e) => {
  e.stopPropagation();
  e.preventDefault();
};

const HeaderSection = ({title, help, extra}) => {
  const extras = extra ? <span onClick={stopPropagation}><Space>{extra}</Space></span> : '';
  return <React.Fragment>
    <Help help={help}>{title}</Help>
    {extras}
  </React.Fragment>;
};

export default memo(HeaderSection);


HeaderSection.propTypes = {
  extra: PropTypes.any,
  help: PropTypes.string,
  title: PropTypes.string
};
