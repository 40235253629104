import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './Container';
import classNames from 'classnames';


const BackgroundSide = styled.div`
  background-image: url('${({ image }) => image}');
  background-size: cover;
  background-color: ${({ background }) => background};

`;

const ColorBackground = styled.div`
  background-color: ${({ background }) => background};
`;


const AuthLayout = ({ image, background, formBackground, leftClass, rightClass, rightComponent, children, layout, className, pageBackground }) => {

    return (
        <Container className={classNames(layout, className)} background={pageBackground}>
            <div className={'dof-auth-layout-container'}>

                <BackgroundSide image={image} background={background} className={classNames('imageContainer', leftClass)}>
                    {rightComponent}
                </BackgroundSide>

                <ColorBackground image={image} background={formBackground} className={classNames('formContainer', rightClass)}>
                    {children}
                </ColorBackground>

            </div>
        </Container>
    );

};

export default memo(AuthLayout);

AuthLayout.propTypes = {
    /** Background image. */
    image: PropTypes.string,
    /** Background color, util for png image or during the image load*/
    background: PropTypes.string,
    /** Background color of the form*/
    formBackground: PropTypes.string,
    /** Background color of the page (just for center layout)*/
    pageBackground: PropTypes.string,
    /** Form or whatever you what to render in teh right side*/
    children: PropTypes.any,
    /** Class of the right class*/
    rightClass: PropTypes.string,
    /** Class of the left class*/
    leftClass: PropTypes.string,
    /** In case you what to render some content inside the image side*/
    rightComponent: PropTypes.any,
    /** ClassName for the container*/
    className: PropTypes.string,
    layout: PropTypes.oneOf(['sideBySide', 'centerbox', 'center'])
};

AuthLayout.defaultProps = {
    layout: 'sideBySide',
    background: '#f0f3f7',
    pageBackground:'#DDE4EC',
    formBackground: '#ffffff'
};
export const LAYOUT = {
    SIDE_BY_SIDE: 'sideBySide',
    CENTER_BOX: 'centerbox',
    CENTER: 'center'
};


BackgroundSide.propTypes = {
    background: PropTypes.string,
    image: PropTypes.string
};
