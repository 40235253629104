import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';
import SpaceModuleCard from '../SpaceModuleCard';
import {getModulesFormSpace} from '../../constant/modules.constant';

const SpaceModuleList = ({modules, active}) => {
  const data = useMemo(() => getModulesFormSpace(modules, active), [modules, active]);

  return (<List
    grid={{
      gutter: 20,
      xs: 1,
      xxl: 2
    }}
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <SpaceModuleCard {...item} />
      </List.Item>
    )}
  />);
};

SpaceModuleList.propTypes = {
  modules: PropTypes.string,
  active: PropTypes.bool,
};

export default memo(SpaceModuleList);


