import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
  display: flex;

.dof-auth-layout-container {
  display: flex;
  width: 100%;
}

.imageContainer {
  padding: 17px;
}

.formContainer {
  padding: 17px;
  display: flex;
  width: 100%;
  justify-content: center;
  >div{
    width: 100%;
    max-width: 368px;
  }
}

@media (min-width: 768px) {
  .imageContainer {
    padding: 40px;
  }

  .formContainer {
    padding: 40px;
  }
}

&.sideBySide {
  flex-direction: row;

  .imageContainer {
    display: none;
  }


  @media (min-width: 768px) {
    .imageContainer {
      display: block;
      width: 50%;
    }

    .formContainer {
      width: 50%;
      align-items: center;
    }
  }
}

&.centerbox {
  justify-content: center;
  align-items: center;
  background-color: #DDE4EC;

  .dof-auth-layout-container {
    max-width: 741px;
    min-height: 403px;
    border-radius: 6px;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .16), 0 3px 6px 0 rgba(0, 0, 0, .12), 0 5px 12px 2px rgba(0, 0, 0, .02);
    flex-direction: column;
    margin: 10px;
  }
  .imageContainer {
    min-height: 65px;
  }
  @media (min-width: 768px) {
    .dof-auth-layout-container {
      flex-direction: row;
    }
    .imageContainer {
      width: 293px;
      border-radius: 6px 0 0 6px;
    }

    .formContainer {
      flex-grow: 1;
      border-radius: 0 6px 6px 0;
    }
  }
}

&.center {
  .dof-auth-layout-container {
    justify-content: center;
    align-items: center;
    background-color: ${({ background }) => background};
    position: relative;

    .imageContainer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .formContainer {
      z-index: 1;
      background-color: transparent;
    }
  }
}
`;

export default Container;
