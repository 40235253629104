import React, { memo } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './index.less';

const { Text } = Typography;

const InfoBox = ({ icon, title, description }) => {
  return (
    <div className={classNames(styles.boxContainer, 'mt-2')}>
      <div className='flex flex-row'>
        <div className='mr-2'>{icon || <InfoCircleOutlined />}</div>
        <div>
          <div className='mb-1'>
            <Text>{title}</Text>
          </div>
          {!!description && (
            <div>
              <Text>{description}</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string
};

export default memo(InfoBox);
