import {isEmail} from 'validator';
import ValidatorProvider from './ValidatorProvider';

export const isUsername = (value) => {
  const usernameRegx = /^[a-zA-Z0-9](_(?!([._]))|\.(?!([_.]))|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$/igm;
  return !!value.match(usernameRegx);
};

export const UsernameRuler = {
  validator(rule, value) {
    if (!value || isUsername(value)) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidUsername';

    return Promise.reject(ValidatorProvider.t(message));
  },
};

export const UsernameOrEmailRuler = {
  validator(rule, value) {
    let validate = isUsername;
    if(value && value.indexOf('@')!==-1)
      validate = isEmail;

    if (!value|| validate(value)) {
      return Promise.resolve();
    }

    const message = rule.message||'invalidUsernameOrEmail';

    return Promise.reject(ValidatorProvider.t(message));
  },
};

