import React, {memo} from 'react';
import {Collapse} from 'antd';
import PropTypes from 'prop-types';
import style from './index.less';
import classNames from 'classnames';
import HeaderSection from './HeaderSection';

const {Panel} = Collapse;
// const {Title} = Typography;


const CollapseSection = ({title, help, extra, children, className}) => {
  const header = <HeaderSection title={title} help={help} extra={extra}/>;

  return (
    <Collapse
      defaultActiveKey={['1']}
      className={classNames(style.collapseSection, className)}>
      <Panel header={header} key="1">
        {children}
      </Panel>
    </Collapse>
  );
};

export default memo(CollapseSection);

CollapseSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  extra: PropTypes.any,
  help: PropTypes.string,
  title: PropTypes.string
};

HeaderSection.propTypes = {
  extra: PropTypes.any,
  help: PropTypes.string,
  title: PropTypes.string
};
