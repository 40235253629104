import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './index.less';
import CustomColorPicker from './customColorPicker';
import classNames from 'classnames';

const ThemeColor = ({
  value,
  onChange,
  colors,
  allowCustom,
  size,
  borderRadius,
  initialValue
}) => {
  const [color, setColor] = useState(value || initialValue);
  const [colorCustom, setColorCustom] = useState('');

  useEffect(() => {
    if (value && value !== initialValue) setColor(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleBtnColor = useCallback(
    (color) => {
      setColor(color);
      setColorCustom('');
      onChange && onChange(color);
    },
    [onChange]
  );

  const handleChange = useCallback(
    (selection) => {
      setColor(selection.hex);
      setColorCustom('#1C7BF7');
      onChange && onChange(selection.hex);
    },
    [onChange]
  );

  return (
    <div className={classNames(style.mainContainer, 'flex inline-block m-2')}>
      {colors.map((colorItem, index) => {
        const isSelect = colorItem === color;
        return (
          <div
            key={index}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              backgroundColor: colorItem,
              borderRadius: `${borderRadius}px`,
              borderColor: isSelect ? '#1C7BF7' : colorItem
            }}
            className={style.divBtnTheme}
            onClick={() => handleBtnColor(colorItem)}
          />
        );
      })}
      {!!allowCustom && (
        <CustomColorPicker
          value={color}
          onChange={handleChange}
          colors={colors}
          borderColor={colorCustom}
        />
      )}
    </div>
  );
};

ThemeColor.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.number,
  borderRadius: PropTypes.number,
  value: PropTypes.any,
  allowCustom: PropTypes.bool
};

ThemeColor.defaultProps = {
  colors: [
    '#39B0FF',
    '#04B58B',
    '#3E9C4B',
    '#B6BC00',
    '#E59100',
    '#E55C00',
    '#EE1F50',
    '#D6198A',
    '#B321F1'
  ],
  allowCustom: true,
  initialValue: '#39B0FF',
  size: 45,
  borderRadius: 23
};

export default memo(ThemeColor);
