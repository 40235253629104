import IFilter from './IFilter'
import { getMongoOperator } from './utils/mongo.utils'

/**
 * This class handle the operators like AND, OR, IN, NOR etc
 * @author Yadian
 * Example
 * {
    "type": "AND",
    "filters": [
    {
        "type": "RANGE",
        "field": "createdAt",
        "from": "2020-07-10",
        "to": "2020-07-16"
    },
    {
        "type": "TERM",
        "field": "um.id.keyword",
        "value": "bb4cd88-c6b8-4a77-956a-0671189be008"
    }
]
 **/


export default class OperatorFilter extends IFilter {
  
  /**
   * @param filter {Object}  { type: String, filters : Array< Object | IFilter > }
   **/
  constructor (filter) {
    super()
    const { type, filters } = filter
    this.type = type.toUpperCase()
    this.filters = this.buildFilters(this._build(filters))
  }
  
  _build (filters) {
    if (!filters) return []
    if (!Array.isArray(filters))
      return [filters]
    return filters
  }
  
  remove () {
    throw new Error('Not implemented yet!')
  }
  
  clone () {
    return new OperatorFilter(this.toQuery())
  }
  
  add (filter) {
    const alreadyExist = this.filters.some(item => filter === item
      || (filter.type && filter.field && filter.type === item.type && filter.field === item.field))
    if (!alreadyExist)
      this.filters.push(filter)
    return this
  }
  
  toQuery () {
    const filters = this.filters.map(filter => filter.toQuery()).filter(filter => filter)
    if (!filters.length)
      return
    if (filters.length === 1)
      return filters[0]
    return {
      type: this.type,
      filters
    }
  }
  
  toMongoQuery () {
    const filters = this.filters.map(filter => filter.toMongoQuery()).filter(filter => filter)
    if (!filters.length)
      return
    if (filters.length === 1)
      return filters[0]
    return {
      [getMongoOperator(this.type)]: filters
    }
  }
  
  /*UTILS*/
  get isOperator () {
    return true
  }
  
  removeFilter (filter) {
    const alreadyExist = this.filters.findIndex(item => filter === item
      || (filter.type && filter.field && filter.type === item.type && filter.field === item.field))
    if (alreadyExist !== -1)
      this.filters.splice(alreadyExist, 1)
    return this
  }
}


