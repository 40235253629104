import React, {memo} from 'react';
import {Card, Statistic} from 'antd';
import PropTypes from 'prop-types';

const StatisticSimple = (props) => {
  return (
    <Card>
      <Statistic
        className={'text-center'}
        valueStyle={{fontSize: '18px', fontWeight: '500'}}
        {...props}
      />
    </Card>
  );

};

export default memo(StatisticSimple);

StatisticSimple.propTypes = {
  loading: PropTypes.bool,
  precision: PropTypes.number,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  value: PropTypes.number
};
