const useStepStatus = (step, currentStep) => {
  if (currentStep == step)
    return 'process';
  if (currentStep > step) {
    return 'finish';
  }
  if (currentStep < step) {
    return 'wait';
  }
};

export default useStepStatus;
