import React, {memo} from 'react';
import {Avatar, PageHeader, Tabs} from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './index.less';
import {useHistory, useLocation} from 'react-router-dom';

const {TabPane} = Tabs;

const TabTitle = ({icon, title}) => {
  return (
    <span className={'px-2'}>
      {icon}
      {title}
    </span>
  );
};

const Links = ({links}) => {
  const {pathname} = useLocation();
  const {push} = useHistory();
  if (!links) return;

  const tabs = links.map((item) => (
    <TabPane key={item.link} tab={
      <TabTitle {...item} />
    }/>
  ));

  return (
    <Tabs activeKey={pathname} onChange={push}>
      {tabs}
    </Tabs>
  );
};

const AvatarSection = ({avatar}) => {
  if (!avatar) return '';
  return (
    <Avatar {...avatar} size={avatar.size || 153} className={classNames(avatar.className, 'ml-5 mr-1 mt-5')}/>
  );
};

const DflPageHeader = ({avatar, title, subTitle, extra, links, ghost, className, dark, center, ...props}) => {

  return (
    <div className={
      classNames(
        'flex flex-col lg:flex-row',
        {'bg-white': !ghost},
        {[style.dark]: dark},
        {[style.center]: center},
        {'center-content p-0': center},
      )
    }>
      <AvatarSection avatar={avatar}/>
      <PageHeader
        {...props}
        className={classNames(className, 'flex-grow mt-2', style.pageHeader)}
        title={title}
        subTitle={subTitle}
        extra={extra}
        footer={<Links links={links}/>}
      />
    </div>
  );

};

export default memo(DflPageHeader);

DflPageHeader.defaultProps = {
  ghost: true
};

DflPageHeader.propTypes = {
  avatar: PropTypes.object,
  className: PropTypes.string,
  dark: PropTypes.bool,
  center: PropTypes.bool,
  extra: PropTypes.array,
  ghost: PropTypes.bool,
  links: PropTypes.array,
  subTitle: PropTypes.any,
  title: PropTypes.any
};

TabTitle.propTypes = {
  icon: PropTypes.any,
  link: PropTypes.string,
  title: PropTypes.string
};

Links.propTypes = {
  links: PropTypes.array
};

AvatarSection.propTypes = {
  avatar: PropTypes.object
};

