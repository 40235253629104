import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LockOutlined, UnlockOutlined} from '@ant-design/icons';

const SpacePrivacy = ({value}) => {
  const {t} = useTranslation('space');
  const styleIcon = {width: '9.88px', height: '12.97px', fontSize: '12.97px'};

  return value ? (
    <React.Fragment>
      <LockOutlined style={styleIcon} className={'mr-2'}/> {t('space.table.privacy.private')}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <UnlockOutlined style={styleIcon} className={'mr-2'}/> {t('space.table.privacy.public')}
    </React.Fragment>
  );
};

SpacePrivacy.propTypes = {
  value: PropTypes.bool
};
SpacePrivacy.defaultProps = {
  value: false
};

export default memo(SpacePrivacy);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Email component in a table cell
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderPrivacySpaceCell = (value, entity) => {
  return <SpacePrivacy value={value} entity={entity}/>;
};
