import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import classNames from 'classnames';

const SelectionBoxItems = ({ value, options, disabled, onChange }) => {
  return (
    <div
      className={classNames(
        styles.boxContainer,
        'flex flex-row items-center flex-1 justify-start'
      )}
    >
      {options.map((item, index) => {
        return (
          <div
            onClick={() => onChange && onChange(item?.value, index, item)}
            className={classNames(
              styles.boxItem,
              'mr-2 flex items-center justify-center py-2 px-4',
              { 'cursor-pointer': !disabled },
              { 'cursor-not-allowed opacity-50': disabled },
              { 'border-primary': value === item?.value },
              { 'text-primary': value === item?.value }
            )}
            key={index}
          >
            {item?.label || item?.value}
          </div>
        );
      })}
    </div>
  );
};

SelectionBoxItems.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func
};

SelectionBoxItems.defaultProps = {
  options: []
};

export default memo(SelectionBoxItems);
