import {ReactComponent as BusinessIcon} from '../assets/modules/businessicon.svg';
import {ReactComponent as EcommerceIcon} from '../assets/modules/ecommerceicon.svg';
import {ReactComponent as HHRRIcon} from '../assets/modules/hhrricon.svg';
import {ReactComponent as ProjectsIcon} from '../assets/modules/projectsicon.svg';
import {ReactComponent as BusinessModule} from '../assets/modules/business.module.svg';
import {ReactComponent as EcommerceModule} from '../assets/modules/ecommerce.module.svg';
import {ReactComponent as HHRRModule} from '../assets/modules/hhrr.module.svg';
import {ReactComponent as ProjectsModule} from '../assets/modules/projects.module.svg';

import {CommentOutlined, DollarOutlined, FileProtectOutlined, SearchOutlined} from '@ant-design/icons';

export const MODULES = {
  PROJECT: 'PROJECT',
  RRHH: 'RRHH',
  DOCUMENT: 'DOCUMENT',
  MEET: 'MEET',
  BUSINESS_INTELLIGENCE: 'BUSINESS_INTELLIGENCE',
  CRM: 'CRM',
  FINANCE: 'FINANCE',
  SEARCH_ENGINE: 'SEARCH_ENGINE'
};

export const MODULES_MAP = {
  PROJECT: {
    key: 'PROJECT',
    module: 'PROJECT',
    available: true,
    icon: ProjectsIcon,
    image: ProjectsModule
  },
  RRHH: {
    key: 'RRHH',
    module: 'RRHH',
    available: true,
    icon: HHRRIcon,
    image: HHRRModule
  },
  DOCUMENT: {
    key: 'DOCUMENT',
    module: 'DOCUMENT',
    available: false,
    icon: FileProtectOutlined,
    image: BusinessModule
  },
  MEET: {
    key: 'MEET',
    module: 'MEET',
    available: false,
    icon: CommentOutlined,
    image: BusinessModule
  },
  BUSINESS_INTELLIGENCE: {
    key: 'BUSINESS_INTELLIGENCE',
    module: 'BUSINESS_INTELLIGENCE',
    available: false,
    icon: BusinessIcon,
    image: BusinessModule
  },
  CRM: {
    key: 'CRM',
    module: 'CRM',
    available: false,
    icon: EcommerceIcon,
    image: EcommerceModule
  },
  FINANCE: {
    key: 'FINANCE',
    module: 'FINANCE',
    available: false,
    icon: DollarOutlined,
    image: BusinessModule
  },
  SEARCH_ENGINE: {
    key: 'SEARCH_ENGINE',
    module: 'SEARCH_ENGINE',
    available: false,
    icon: SearchOutlined,
    image: BusinessModule
  }
};


export const MODULES_LIST = Object.keys(MODULES_MAP).map((key) => MODULES_MAP[key]);

export const getModuleInfo = (module) => {
  return MODULES_MAP[module];
};

export const getModulesFormSpace = (modules, filterActive) => {
  let modulesEntries = modules ? Object.keys(modules) : [];
  if (filterActive)
    modulesEntries = modulesEntries.filter((module) => modules[module].enabled);
  return modulesEntries.map((module) => Object.assign({}, modules[module], MODULES_MAP[module]));
};
