import React from 'react';
import {Card, Typography} from 'antd';
import PropTypes from 'prop-types';

const {Title, Link} = Typography;

const MediumBoxWithHeader = ({title, children, textLink, onClick}) => {
  return (
    <Card className="mt-4">
      <Title level={5}>{title}</Title>
      {children}

      {
        textLink
          ? <div className="flex justify-center"><Link onClick={onClick}> {textLink} </Link></div> : ''
      }
    </Card>
  );
};

export default MediumBoxWithHeader;

MediumBoxWithHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  textLink: PropTypes.string,
  onClick: PropTypes.func,
};

