import ObjectID from 'bson-objectid';
import IFilter from './IFilter'
import EmptyFilter from './EmptyFilter'
import { getMongoOperator } from './utils/mongo.utils'

/**
 * This class handle the operator IN
 * @author Reynaldo
 * Example
 * {
    "type": "IN",
    "field": "operator"
    "value": ["bb4cd88-c6b8-4a77-956a-0671189be008"]
    }
 * Result toMongoQuery
 * { "operator": { $in: ["bb4cd88-c6b8-4a77-956a-0671189be008"] } }
 **/


export default class InFilter extends IFilter {

  /**
   * @param filter {Object}
   **/
  constructor (filter) {
    super()
    const { field, value, objectId } = filter
    this.type = 'IN'
    this.field = field
    this.value = value || []
    this.filterObject = filter
    this.objectId = objectId || false
  }

  clone () {
    return new OperatorINFilter(this.toQuery())
  }

  toQuery () {
    if (!this.field)
      return this.filterObject
    if (this.value === undefined)
      return
    return {
      type: 'IN',
      field: this.field,
      value: this.value,
      objectId: this.objectId
    }
  }

  toMongoQuery () {
    if (!this.field)
      return this.filterObject
    let nValues = this.value
    if (this.objectId){
      nValues = this.value.map(value => new ObjectID(value))
    }
    return { [this.field]: { [getMongoOperator(this.type)]: nValues } }
  }

  removeFilter () {
    return new EmptyFilter()
  }
}


