import {isCreditCard} from "validator";
import ValidatorProvider from "./ValidatorProvider";

export {
  isCreditCard
}

export const CreditCardRuler = {

  validator(rule, value) {
    if (isCreditCard(value) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidCreditCard';

    return Promise.reject(ValidatorProvider.t(message));
  },

};


export const FORMAT = {
  'YYMM': /^\d\d\d\d$/,
  'YY/MM': /^\d\d\/\d\d$/
};

export const isExpiry = (value = '', format = 'YY/MM') => {
  const isNumber = value.match(FORMAT[format]);
  if (isNumber) {
    const yy = Number(value.substring(0, 2));
    const mm = Number(value.substr(-2));
    return yy > 0 && yy < 13 && mm > 0 && mm < 31;
  }
  return false;
};


export const ExpiryDateRuler = {

  validator(rule, value) {
    if (isExpiry(value) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidExpiryDate';

    return Promise.reject(ValidatorProvider.t(message));
  },

};

export const isCvc = (value = '') => {
  return value.match(/^\d{3}$|^\d{4}$/);
};


export const CvcRuler = {

  validator(rule, value) {
    if (isCvc(value) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidCvc';

    return Promise.reject(ValidatorProvider.t(message));
  },

};


